import { Container, Row, Col } from "react-bootstrap";
import Link from "next/link";
import { parseHtml } from "../../../utils/common";
import React from "react";
import dynamic from "next/dynamic";

const BannerSlider = dynamic(() => import("./banner-slider"), {
	ssr: true,
})

const BannerSection = (props) => {
	const slides = props.slides;

	return (
		<section className="banner-section video-banner">
			<div className="banner-inner">
				<Container>
					<Row className="mobile-banner g-0">
						<Col xl={7} lg={7} md={12} className="banner-contents">
							<div className="banner-heading">
								<h1 id="target">
									{parseHtml(slides[0].Description)}
								</h1>
								<Link
									href={slides[0].sliderButtonLink}
									className="btn btn-primary"
								>
									{slides[0].SlideButtonText}
								</Link>
							</div>
						</Col>
						<Col xl={5} lg={5} md={12} className="banner-sliders" style={{minHeight:'546px'}}>
							<BannerSlider slides={slides} />
						</Col>
					</Row>
				</Container>
				<Container>
					<div className="banner-solution-box">
						<Row>
							{slides.map((slide) => {
								return (
									<Col lg={3} md={6} sm={6} key={slide.id}>
										<Link
											href={slide?.button?.href || '#'}
											className="solution-btn btn"
										>
											<span className="solution-btn-text">
												{slide?.button?.label
													? parseHtml(
														slide.button.label
													)
													: ""}
											</span>
											<span className="arrow"></span>
										</Link>
									</Col>
								);
							})}
						</Row>
					</div>
				</Container>
			</div>
		</section >
	);
}

// const BannerSectionViewportBlock = handleViewport(BannerSection);

export default BannerSection;
